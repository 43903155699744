var formatter = new Intl.NumberFormat("de-DE", {
	style: "currency",
	currency: "EUR",
	maximumFractionDigits: 0,
	minimumFractionDigits: 0,
});

function isWhatPercentOf(numA, numB) {
	return Math.min(Math.max((numA / numB) * 100, 1), 100);
}

const sliderEl = document.querySelector('.slider__range');
const currentEl = document.querySelector('.current-value')
const maklerEl = document.querySelector('.makler')
const inserateEl = document.querySelector('.static-bar--inserate')
const vermarktungEl = document.querySelector('.static-bar--vermarktung')

// Default values
const maklerPercentage = 0.0357
let maklerValue = 13.138
const vermarktungValue = 2499
const inserateValue = 299

// Change Calculator Values
function changeCurrentValue(value) {
	currentEl.textContent = formatter.format(value)
}

function changeMaklerValue(value) {
	maklerValue = value * maklerPercentage
	maklerEl.textContent = formatter.format(maklerValue)
}

// React to slider changes
// 'input' is used to change values constantly instead of when the user releases the input
sliderEl.addEventListener('input', event => {
	changeCurrentValue(event.target.value)
	changeMaklerValue(event.target.value)
	inserateEl.style.setProperty('--percentage', isWhatPercentOf(inserateValue, maklerValue) + "%");
	vermarktungEl.style.setProperty('--percentage', isWhatPercentOf(vermarktungValue, maklerValue) + "%");
})


// This loop allows all range input elements to show a trailing progress bars
// with the '.slider-progress' class. This functionality is not supported on
// custom styled range inputs by default
for (let e of document.querySelectorAll('input[type="range"].slider-progress')) {
	e.style.setProperty('--value', e.value);
	e.style.setProperty('--min', e.min == '' ? '0' : e.min);
	e.style.setProperty('--max', e.max == '' ? '100' : e.max);
	e.addEventListener('input', () => e.style.setProperty('--value', e.value));
}

const priceModal = document.querySelector('.preise-modal');
const rentSwitches = document.querySelectorAll('.rent-switch');
const sellSwitches = document.querySelectorAll('.sell-switch');

const switchSellClasses = () => {
	for (let e of sellSwitches) {
		e.classList.add('btn-primary');
		e.classList.remove('btn-outline-primary');
	}
}

const switchRentClasses = () => {
	for (let e of rentSwitches) {
		e.classList.add('btn-primary');
		e.classList.remove('btn-outline-primary');
	}
}

for (let e of sellSwitches) {

	// Add click event listener to all sell switches
	e.addEventListener('click', () => {
		if (priceModal.classList.contains('sell')) {
			return;
		}

		// Add active classes to sell switches
		switchSellClasses();

		// Show 'sell' information within modal using classes
		priceModal.classList.add('sell');
		priceModal.classList.remove('rent');

		// Remove active classes from all rent switches
		for (let e of rentSwitches) {
			e.classList.remove('btn-primary');
			e.classList.add('btn-outline-primary');
		}
	})
}

for (let e of rentSwitches) {
	// Add click event listener to all rent switches
	e.addEventListener('click', () => {
		if (priceModal.classList.contains('rent')) {
			return;
		}

		// Add active classes to rent switches
		switchRentClasses();

		// Show 'sell' information within modal using classes
		priceModal.classList.add('rent');
		priceModal.classList.remove('sell');

		// Remove active classes from all rent switches
		for (let e of sellSwitches) {
			e.classList.remove('btn-primary');
			e.classList.add('btn-outline-primary');
		}
	})
}

// Scroll to Calendar when closing Vermarktung Modal
const vermarktungModal = new bootstrap.Modal(document.getElementById('vermarktungModal'))
const vermarktungActionButton = document.querySelector('.vermarktung-action')

vermarktungActionButton.addEventListener('click', e => {
	const calendarScrollBox = document.querySelector('.calendar-container')
	e.preventDefault()
	vermarktungModal.hide()

	setTimeout(function () {
		calendarScrollBox.scrollIntoView();
	}, 500)
})

// Animate Calendly When scrolled into view
const observer = new IntersectionObserver(entries => {
	entries.forEach(entry => {
		const calendar = entry.target;
		const calendly = entry.target.querySelector('.calendly-inline-widget');

		if (entry.isIntersecting) {
			calendar.classList.add('calendar-transition');
			calendly.classList.add('calendly-inline-widget-transition');
			return; // if we added the class, exit the function
		}

		// We're not intersecting, so remove the class!
		calendar.classList.remove('calendar-transition');
		calendly.classList.remove('calendly-inline-widget-transition');
	});
});

observer.observe(document.querySelector('.calendar'));
